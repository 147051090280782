.timeline {
  display: flex;
  width: 100%;
  /* border: 1px solid blue; */
  height: 2rem;
  margin-bottom: 0.5rem;
}

.timelineFront {
  width: 13rem;
  min-width: 13rem;
  position: relative;
  /* border: 1px solid blue; */
}

.timelineBack {
  width: 100%;
  position: relative;
  /* border: 1px solid blue; */
}

.timelineLocators {
  position: relative;
  top: 0rem;
  background-color: lightskyblue;
  width: 100%;
  height: 1rem;
  overflow: hidden;
}

.timelineRuler {
  position: relative;
  top: 0rem;
  background-color: lightgray;
  width: 100%;
  height: 1rem;
  overflow: hidden;
}

.timelineTick {
  position: absolute;
  border-left: 1px solid black;
  height: 0.5rem;
}

.timelineMajorTick {
  position: absolute;
  border-left: 2px solid black;
}

.timelineTickLabel {
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  font-size: 0.75rem;
  margin-left: 0.1rem;
}

.loop {
  position: absolute;
  height: 100%;
  border: 2px solid black;
}

/* .loop:hover {
  background-color: rgba(0, 0, 0, 0.25);
  cursor: grab;
} */

/* .dragHandle {
  height: '100%';
  width: '100%';
  margin-left: '0.7rem';
  margin-right: '0.7rem';
  background-color: 'darkskyblue';
  border: '2px solid red';
} */

.dragHandle:hover {
  background-color: rgba(0, 0, 0, 0.25);
  cursor: grab;
}

.locator:hover {
  cursor: col-resize;
}