.hours {
  max-width: 1.5rem;
  width: 1.5rem;
  text-align: center;
}

.minutes {
  max-width: 1.5rem;
  width: 1.5rem;
  text-align: center;
}

.seconds {
  max-width: 1.5rem;
  width: 1.5rem;
  text-align: center;
}

.milliseconds {
  max-width: 1.5rem;
  width: 1.5rem;
  text-align: center;
}