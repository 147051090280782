.region {
  position: absolute;
  border: 1px solid gray;
  border-radius: 0.25rem;
  border-width: 2px;
  /* padding: 0.25rem; */
  font-size: x-small;
  font-weight: bold;
  overflow: hidden;
  text-wrap:nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.1s ease-in-out;
  /* z-index: 1000000; */
}

.region:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}

.handles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: row;
}

.leftHandle {
  width: 4px;
  min-width: 4px;
  height: 100%;
  flex: 0;
  cursor: col-resize;
  z-index: 100;
  /* color: turquoise; */
  /* border: 1px solid gray; */
}

.rightHandle {
  width: 4px;
  min-width: 4px;
  height: 100%;
  flex: 0;
  cursor: col-resize;
  z-index: 100;
  /* color: turquoise; */
  /* border: 1px solid gray; */
}

.centerHandle {
  height: 100%;
  flex: 1;
  cursor: move;
}

.loopHandle {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 8px;
  height: 8px;
  cursor: url(../../public/loop-cursor.png), auto;
  z-index: 110;
  /* border: 1px solid gray; */
}