.transport {
  flex: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  align-items: center;
}

.header {
  font-weight: bold;
}

.bpm {
  width: 2rem;
}

.denominator {
  width: 1rem;
}

.numerator {
  width: 1rem;
}

.zoomButtons {
  align-self: right;
}

.spacer {
  flex-grow: 1;
}