.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.arrangement {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 20rem;
  width: auto;
  height: auto;
}

.timelineRow {
  display: flex;
  height: 2rem;
  min-height: 2rem;
  overflow-y: hidden;
  margin-bottom: 0.5rem;
  flex: 0;
}

.placeholder {
  width: 256px;
  min-width: 256px;
}

.timelineScroll {
  flex: 1;
  height: 3rem;
  min-height: 3rem;
  width: auto;
  /* background: #ccc; */
  display: flex;
  overflow-x: scroll;
}

.timelineArea {
  flex-shrink: 0;
}

.tracks {
  display: flex;
  flex-direction: row;
  flex-basis: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 80px;
}

.trackScrollOuter {
  overflow-x: hidden;
  width: 16rem;
  min-width: 16rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.trackScroll {
  width: 18rem;
  min-width: 18rem;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  /* border: 1px solid black ; */
  scrollbar-gutter: 0px;
}

.trackInfoBox {
  width: 16rem;
  min-width: 16rem;
  max-width: 16rem;
  display: flex;
  flex-direction: row;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15); */
  transition: box-shadow 0.1s ease-in-out;
}

i.grip::after {
  content: "..................";
  min-width: 1rem;
  max-width: 1rem;
  width: 1rem;
  display: inline-block;
  /* color: #AAA; */
  font-style: normal;
  font-family: sans-serif;
  font-size:15px;
  font-weight: bold;
  transform: rotate(90deg);
  cursor:move;
  margin-right:0.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
  /* border: 1px solid black; */
}

.regionScroll {
  width: auto;
  height: auto;
  overflow-y: scroll;
  overflow-x: scroll;
  flex: 1;
}

.marker {
  position: relative;
  height: 100%;
  width: 1px;
  border: 1px solid gray;
  top: 0;
  /* z-index: 1000000 */
}

.trackInfoPlaceholder {
  width: 15rem;
  min-width: 15rem;
  height: 100%;
  flex: 0;
  /* background-color: gainsboro; */
  vertical-align: middle;
  text-align: right;
  /* line-height: 100px; */
}

.trackPlaceholder {
  flex: 0;
  background-color: gainsboro;
  /* border: 1px solid black; */
  position: absolute;
  left: 0;
  width: 100%;
  vertical-align: middle;
  padding-left: 2rem;
}

.dragMarker {
  position: absolute;
  height: 3px;
  width: 100%;
  border: 1px solid gray;
  top: 0;
}

.dragging {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);  
}

.regionPlaceholder {
  border: 2px solid gray;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.10);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);  
  z-index: 1000000;
}