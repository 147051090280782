.bar {
  max-width: 2.5rem;
  width: 2.5rem;
  text-align: center;
}

.beat {
  max-width: 1.0rem;
  width: 1.0rem;
  text-align: center;
}

.tick {
  max-width: 2rem;
  width: 2rem;
  text-align: center;
}