.center {
  width: auto;
  max-height: inherit;
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.browser {
  flex: 0;
  min-height: 10rem;
  max-height: inherit;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.browserInner {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.separator {
  width: 5px;
  min-width: 5px;
  max-width: 5px;
  height: 100%;
  /* background: #ccc; */
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  flex: 0;
  cursor: col-resize;
  border-left: 2px solid gainsboro;
  border-right: 2px solid gainsboro;
}