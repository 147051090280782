.dragLabel {
  position: fixed;
  z-index: 1000000;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.notDownloaded {
  color: #ccc;
  filter: grayscale(100%);
}

.canDrag {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
}